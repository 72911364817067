import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
} from "reactstrap";

import * as Icon from "react-feather";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import AgencyFeature from "../../component/AgencyFeature";
import AgencyProject from "../../component/AgencyProject";
import Pricing from "../../component/Pricing";
import AgencyTeam from "../../component/AgencyTeam";
import News from "../../component/News";

import BackgroundImage1 from "../../assets/images/new/new-header-selamat-datang-lands.jpg";
import BackgroundImage2 from "../../assets/images/new/new-header-selamat-datang-potr.jpg";
import P1Lands from "../../assets/images/new/kristal-collagen-lands.jpg";
import P1Potr from "../../assets/images/new/kristal-collagen-potr.jpg";
import P2Lands from "../../assets/images/new/kristal-sirih-lands.jpg";
import P2Potr from "../../assets/images/new/kristal-sirih-potr.jpg";
import AmazonImage from "../../assets/images/client/amazon.svg";
import GoogleImage from "../../assets/images/client/google.svg";
import LenovoImage from "../../assets/images/client/lenovo.svg";
import PaypalImage from "../../assets/images/client/paypal.svg";
import ShopifyImage from "../../assets/images/client/shopify.svg";
import SpotifyImage from "../../assets/images/client/spotify.svg";
import MapImage from "../../assets/images/map.png";
import AboutImage from "../../assets/images/about-gss.jpg";
import AboutImage2 from "../../assets/images/about-gss2.jpg";
import CTAImage from "../../assets/images/bg/cta.png";
import Logodark from "../../assets/images/logo-dark.png";
import Logolight from "../../assets/images/logo-gss-light.png";
import Feature from "../../component/Feature";
import style from "../../index.css";
import {
  FaFacebookF,
  FaInstagram,
  FaRegEnvelope,
  FaTiktok,
} from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SimpleSlider() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      <figure className="bg-banner">
        <img src={BackgroundImage1} alt="" className="d-none d-md-block" />
        <img src={BackgroundImage2} alt="" className="d-md-none" />
      </figure>
      <figure className="bg-banner">
        <img src={P1Lands} alt="" className="d-none d-md-block" />
        <img src={P1Potr} alt="" className="d-md-none" />
      </figure>
      <figure className="bg-banner">
        <img src={P2Lands} alt="" className="d-none d-md-block" />
        <img src={P2Potr} alt="" className="d-md-none" />
      </figure>
    </Slider>
  );
}

export default function Agency() {
  const [isMenuOpen, setMenu] = useState(true);
  const [videoModal, setVideoModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [arrow, setArrow] = useState(false);
  const [iscontact, contactModal] = useState(false);

  const handleScroll = () => {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      setArrow(true);
    } else {
      setArrow(false);
    }
  };

  const windowScroll = () => {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  };

  const openModal = () => {
    setVideoModal(true);
  };

  const toggleMenu = () => {
    setMenu(!isMenuOpen);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", windowScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", windowScroll);
    };
  }, []);

  return (
    <>
      <div>
        {/* <ScrollTo> */}
        <nav
          id="navbar"
          className="navbar navbar-expand-lg nav-light fixed-top sticky"
        >
          <div className="container">
            <NavbarBrand className="navbar-brand" href="/">
              <span className="logo-light-mode">
                <img src={Logolight} className="l-light" height="70px" alt="" />
                <img src={Logolight} className="l-dark" height="55px" alt="" />
              </span>
              <img src={Logolight} className="logo-dark-mode" alt="" />
            </NavbarBrand>
            <NavbarToggler onClick={toggleMenu}>
              <Icon.Menu />
            </NavbarToggler>

            <Collapse
              className={`navbar-collapse ${
                isMenuOpen === true ? "hidden" : "show"
              }`}
              id="navbarSupportedContent"
            >
              <Nav
                className="navbar-nav ms-auto mb-2 mb-lg-0"
                id="navbar-navlist"
              >
                <NavItem>
                  <Link
                    activeClass="active"
                    to="homee"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="product"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    Product
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    Opportunity
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="pricing"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    Support System
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="gallery"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    Gallery
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    activeClass="active"
                    to="news"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    News
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link2
                    className="nav-link"
                    to="#"
                    onClick={() => contactModal(true)}
                  >
                    Contact Us
                  </Link2>
                </NavItem> */}
              </Nav>

              <ul className="list-inline menu-social mb-0 ps-lg-4 ms-2">
                <li className="list-inline-item">
                  <Link2
                    to="https://gssindo.co.id/dashboard-gssindo/login"
                    className="btn btn-primary"
                    target="_BLANK"
                  >
                    Login Member
                  </Link2>
                </li>
              </ul>
            </Collapse>
          </div>
        </nav>
        <Modal isOpen={iscontact} toggle={() => contactModal(!iscontact)}>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalBody>
            <form method="post" name="myForm">
              <p id="error-msg" className="mb-0"></p>
              <div id="simple-msg"></div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-normal">
                      Your Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder="Name :"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label fw-normal">
                      Your Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Email :"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label fw-normal">Subject</label>
                    <input
                      name="subject"
                      id="subject"
                      className="form-control"
                      placeholder="subject :"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label fw-normal">
                      Comments <span className="text-danger">*</span>
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      rows={4}
                      className="form-control"
                      placeholder="Message :"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-grid">
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn btn-primary"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>

        {/* <section
          className="bg-home d-flex align-items-center"
          style={{ background: `url(${BackgroundImage1})` }}
          id="home"
        ></section> */}
        <section id="homee">
          <SimpleSlider />
        </section>
        {/* end section */}

        <section className="section" id="home">
          <Container>
            <div style={{ background: `url(${MapImage}) center center` }}>
              <Row className="align-items-center">
                <Col lg={6} md={6}>
                  <div className="position-relative me-lg-5">
                    <img
                      src={AboutImage2}
                      className="rounded img-fluid mx-auto d-block"
                      alt=""
                    />
                  </div>
                </Col>

                <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="section-title">
                    <h4 className="title mb-3">
                      About <br /> PT. GLOBAL STAR SUCCESS
                    </h4>
                    <p className="text-muted mb-5">
                      Global Star Success merupakan perusahaan Multi Level
                      Marketing berskala international. Global Star Success
                      berfokus menjadikan para member menjadi milyader
                      hebat di bisnis GSS.
                    </p>

                    <h4 className="title mb-3">Visi</h4>
                    <p className="text-muted mb-5">
                      MENJADI PERUSAHAAN SOLUSI BISNIS NETWORK MARKETING
                      TERKEMUKA DI INDONESIA YANG TERSEBAR DI BERBAGAI DAERAH,
                      PULAU-PULAU, DAN PROVINSI SERTA MENJADI PERUSAHAAN YANG
                      DAPAT GO INTERNATIONAL
                    </p>

                    <h4 className="title mb-3">Misi</h4>
                    <ul className="list-unstyled text-muted">
                      <li className="mb-1 d-flex">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        <p className="mb-1">
                          MENCETAK INDIVIDU YANG MANDIRI SERTA MEMILIKI JIWA
                          WIRAUSAHA SEHINGGA MAMPU MENJADI PRIBADI YANG SIAP
                          BERSAING
                        </p>
                      </li>
                      <li className="mb-1 d-flex">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        <p className="mb-1">
                          MEMBERIKAN KESEMPATAN YANG SAMA KEPADA MEMBER UNTUK
                          BISA MERAIH SUKSES
                        </p>
                      </li>
                      <li className="mb-1 d-flex">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        <p className="mb-1">
                          MENYEDIAKAN PRODUK DAN JASA YANG LAYAK, DENGAN HARGA
                          TERJANGKAU DAN SALING MENGUNTUNGKAN
                        </p>
                      </li>
                      <li className="mb-1 d-flex">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        <p className="mb-1">
                          MEMBERIKAN PELAYANAN PRIMA KEPADA SEMUA MEMBER DENGAN
                          SEBUAH SYSTEM YANG MUDAH UNTUK DI JALANKAN
                        </p>
                      </li>
                    </ul>

                    <div className="d-inline-block">
                      <div className="pt-3 d-flex align-items-center border-top">
                        <i className="uil uil-envelope text-primary me-2 fs-1"></i>
                        <div className="content">
                          <p className="mb-0">Need More Help?</p>
                          <Link2 to="#" className="text-dark h6">
                            Ask us your question
                          </Link2>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        {/* Feature start */}

        {/* <AgencyFeature /> */}

        <Feature />

        {/* Project start */}
        <AgencyProject />
        {/* Pricing  */}
        {/* <Pricing /> */}

        {/* CTA Start  */}
        {/* <section
          className="section"
          data-jarallax='{"speed": 0.5}'
          style={{ background: `url(${CTAImage}) center` }}
        >
          <div className="bg-overlay"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center">
                  <h4 className="title text-white mb-3">
                    Ready to start your next web project now?
                  </h4>
                  <p className="text-white-50 mx-auto para-desc mb-0">
                    Launch your campaign and benefit from our expertise on
                    designing and managing conversion centered bootstrap v5 html
                    page.
                  </p>

                  <div className="mt-4 pt-2">
                    <Link2 to="#" className="btn btn-primary">
                      Get Started !
                    </Link2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* CTA End  */}

        <AgencyTeam />
        <News />

        <div className="p-5 bg-light"></div>

        {/* Footer Start */}
        <footer className="bg-footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="py-4">
                  {/* <div className="row justify-content-center">
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                      <img
                        src={AmazonImage}
                        className="avatar avatar-ex-sm"
                        alt=""
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                      <img
                        src={GoogleImage}
                        className="avatar avatar-ex-sm"
                        alt=""
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                      <img
                        src={LenovoImage}
                        className="avatar avatar-ex-sm"
                        alt=""
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                      <img
                        src={PaypalImage}
                        className="avatar avatar-ex-sm"
                        alt=""
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                      <img
                        src={ShopifyImage}
                        className="avatar avatar-ex-sm"
                        alt=""
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                      <img
                        src={SpotifyImage}
                        className="avatar avatar-ex-sm"
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="footer-py-30 footer-bar">
            <div className="container text-center">
              <div className="row align-items-center justify-content-center">
                <div className="col-sm-8">
                  <div className="text-sm-start">
                    <p className="mb-0">
                      © {new Date().getFullYear()} PT. Global Star Success
                    </p>
                  </div>
                </div>

                <div className="col-sm-4 mt-4 mt-sm-0">
                  <ul className="list-unstyled social-icon text-sm-end foot-social-icon mb-0">
                    <li className="list-inline-item ms-1">
                      <Link2
                        to="https://www.facebook.com/profile.php?id=61558169991866&mibextid=ZbWKwL"
                        target="_BLANK"
                        className="rounded"
                        title="facebook"
                      >
                        <div className="align-middle">
                          <FaFacebookF size={24} />
                        </div>
                      </Link2>
                    </li>
                    <li className="list-inline-item ms-1">
                      <Link2
                        to="https://www.instagram.com/globalstarsuccess.ofc?igsh=OWE3ZmY3cnE0eHd4"
                        target="_BLANK"
                        className="rounded"
                        title="instagram"
                      >
                        <div className="align-middle">
                          <FaInstagram size={24} />
                        </div>
                      </Link2>
                    </li>
                    <li className="list-inline-item ms-1">
                      <Link2
                        to="http://tiktok.com/@globalstarsuccess.ofc"
                        target="_BLANK"
                        className="rounded"
                        title="tiktok"
                      >
                        <div className="align-middle">
                          <FaTiktok size={24} />
                        </div>
                      </Link2>
                    </li>
                    <li className="list-inline-item ms-1">
                      <Link2
                        to="mailto:globalstarsuccess.official@gmail.com"
                        className="rounded"
                        title="email"
                      >
                        <div className="align-middle">
                          <FaRegEnvelope size={24} />
                        </div>
                      </Link2>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* Footer End  */}
        {/* Back to top */}
        <Link
          to="home"
          style={{ display: arrow === true ? "block" : "none" }}
          id="back-to-top"
          className="back-to-top rounded-pill fs-5"
        >
          <Icon.ArrowUp className="fea icon-sm icons align-middle" />
        </Link>
        {/* Back to top  */}
      </div>
      {/* popup video */}
      <ModalVideo
        channel="youtube"
        isOpen={videoModal}
        videoId="yba7hPeTSjk"
        onClose={() => setVideoModal(false)}
      />
    </>
  );
}
